import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { graphql } from 'gatsby';
import { Button, Content, Spacer, Image } from 'components';
import image404 from 'images/404.svg';
export const query = graphql`
	query {
		image404: file(relativePath: { eq: "404.svg" }) {
			...imageData
		}
	}
`;
export const _frontmatter = {
  "title": "Page not found"
};
const layoutProps = {
  query,
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <Spacer height={161} responsive={{
      md: 140,
      sm: 120
    }} mdxType="Spacer" />
    <Content isCentered mdxType="Content">
	<Image {...props.data.image404} alt="404 not found" mdxType="Image" />
	<Spacer height={27} responsive={{
        md: 20,
        sm: 15
      }} mdxType="Spacer" />
	<h1>What’s the meaning of life?</h1>
	<p className="style:pfeatured">
		We don’t know.
		<br />
		But we know that the page you visited doesn’t exist.
	</p>
	<Spacer height={40} responsive={{
        md: 30,
        sm: 20
      }} mdxType="Spacer" />
	<Button arrow="left" color="berry80" to="/" type="plain-block" mdxType="Button">
		Back to Homepage
	</Button>
	<Spacer height={100} responsive={{
        md: 80,
        sm: 60
      }} mdxType="Spacer" />
    </Content>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      